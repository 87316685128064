<template>
  <v-container fluid>
    <v-row align="center">
      <v-col class="pa-0" cols="1">
        <v-btn class="arrows" icon="fa fa-arrow-left" style="background: transparent" variant="flat"
               @click="scrollLeft"></v-btn>
      </v-col>
      <v-col cols=10>
        <div class="time-grid">
          <v-row class="d-flex">
            <v-col
                v-for="(time, index) in gridTimeBlocks"
                :key="index"
                class="time-cell px-1"
                cols="4"
            >
              <v-btn
                  :class="{'text-white': time === modelValue}"
                  :color="time === modelValue ? color :''"
                  class="b-round-20 transparent"
                  variant="flat"
                  @click="selectTime(time)"
              >
                {{ timesWithAmPm[index + currentBlockIndex] }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="pa-0" cols="1">
        <v-btn class="arrows" icon="fa fa-arrow-right" style="background: transparent" variant="flat"
               @click="scrollRight"></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {ref, computed, defineProps, defineEmits} from 'vue';
import {VContainer, VRow, VCol, VBtn} from 'vuetify/components';

const props = defineProps({
  modelValue: String, // The currently selected time passed from parent component via v-model
  highlightColor: {
    type: String,
    default: 'rgba(0, 255, 0, 0.5)', // Default to a semi-transparent green
  },
  availableTimeslots: {
    type: Array,
    required: true,
    default: () => [],
  },
  color: {
    type: String,
  },
});

const emit = defineEmits(['update:modelValue']);

const currentBlockIndex = ref(0);

// Compute the grid of time blocks to display in a 3x3 grid
const gridTimeBlocks = computed(() => {
  return timesWithAmPm.value.slice(currentBlockIndex.value, currentBlockIndex.value + 9);
});

// Method to handle time selection
const selectTime = (time) => {
  emit('update:modelValue', time); // Emit the selected time to update v-model
};

const timesWithAmPm = computed(() => {
  const times = props.availableTimeslots;
  return times.map(time => {
    const [hour, minute] = time.split(":").map(Number); // Split hour and minute and convert to numbers
    const amPm = hour >= 12 ? "pm" : "am"; // Determine AM/PM
    const hour12 = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
    return `${String(hour12).padStart(2, "0")}:${minute.toString().padStart(2, "0")} ${amPm}`;
  });
});

// Scroll handling
const scrollRight = () => {
  if (currentBlockIndex.value + 9 < props.availableTimeslots.length) {
    currentBlockIndex.value += 9;
  }
};

const scrollLeft = () => {
  if (currentBlockIndex.value - 9 >= 0) {
    currentBlockIndex.value -= 9;
  }
};
</script>

<style scoped>
.time-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows {
  background: re;
  font-size: 14px;
  width: 40px;
}
</style>
  