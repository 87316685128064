<template>
  <v-sheet :color="bgColour">
    <div class="pt-5 pb-2 px-5 d-flex justify-space-between flex-wrap width-small-screen">
      <div v-for="tab in tabs" :key="tab.name" class="width-small-screen">
        <v-btn :class="state.modelValue.method == 'in-person' ? 'text-white' : ''"
               :color="state.modelValue.name == tab.name ? colour : ''"
               :disabled="tab.disabled"
               :variant="state.modelValue.name == tab.name ? 'flat' : 'text'"
               class="b-round-20 width-small-screen"
               @click="state.modelValue = tab;emit('update:modelValue', state.modelValue)">
                    <span :class="dark && state.modelValue.name == tab.name  ? 'text-white' : ''"
                          class="font-size-12-600 text-capitalize d-flex align-center ga-1">
                     <Icon :color="state.modelValue.name == tab.name ? tab.colour : colour" :icon="tab.icon"></Icon>
                      {{ tab.name }}
                    </span>
        </v-btn>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts" setup>
import {defineProps, defineEmits, reactive} from 'vue';
import Icon from "@/components/Icons/Icon.vue";

const props = defineProps({
  modelValue: {
    type: Object,
  },
  colour: {
    type: String,
    default: 'primary',
  },
  bgColour: {
    type: String,
    default: 'transparent',
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  dark: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const state = reactive({
  modelValue: props.modelValue,
  tabs: props.tabs,
  dark: props.dark,
});
</script>

<style lang="scss" scoped>

.border-rounded {
  border-radius: 8px;
  box-shadow: none;
}

.v-picker__header {
  display: none !important;
}

.v-picker-title {
  display: none !important;
}

.width-small-screen {
  @media (max-width: 431px) {
    width: 100%;
  }
}
</style>