<template>
  <section class="my-4">
    <p v-if="filteredInstalments.length > 0" class="font-size-12-600">Instalments</p>

    <div v-for="(instalment, index) in filteredInstalments" :key="`instalment-${index}`"
         class="d-flex justify-space-between">
      <p :class="{'font-danger':instalment?.payment_status === 'not_paid' }" class="font-size-12-600">
        {{ moment(instalment.payment_due ? instalment.payment_due : instalment.created_at).format('DD MMMM YYYY') }}
      </p>
      <p :class="{'font-danger':instalment?.payment_status === 'not_paid' }" class="font-size-12-600">
        {{ supplierOrder?.currency_symbol }}{{ instalment.price }}
      </p>
    </div>
  </section>
</template>

<script lang="ts" setup>
import moment from "moment";
import {computed, defineProps} from 'vue';

const props = defineProps({
  order: {
    type: Object,
  },
  supplierOrder: {
    type: Object,
  },
  isPackage: {
    type: Boolean,
  },
});

const filteredInstalments = computed(() => {
  return props.order?.instalments.filter((instalment: any) => instalment.type !== 'product');
})
</script>