<template>
    <v-container class="text-center">
        <v-row class="mt-2" v-if="state.order">
            <v-col class="pa-2 text-center" cols="12">
                <v-card v-if="state.hasCardPayments">
                    <v-card-title>
                        <p class="p-text-lg">Card Payment</p>
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group row>
                            <v-radio v-for="option in getPaymentOptions" 
                            :key="option.name" 
                            :label="option.label"
                            :value="option.value" 
                            @change="setPaymentData(option.value)"/>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-text v-if="state.paymentSession && state.paymentOption != null">
                        <swan-pay-container :session="state.paymentSession" @payment:success="paymentSuccess()"
                    @payment:error="paymentError()" />
                    </v-card-text>
                    <v-card-text class="text-center" v-else-if="!state.paymentSession && state.paymentOption">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        <p>Loading Payment Session...</p>
                    </v-card-text>
                </v-card>
                <v-card v-else>
                    <v-card-text>
                        <p>Card payments are not enabled.</p>
                    </v-card-text>
                </v-card>
                <v-card v-if="state.paymentInformation !== ''">
                    <v-card-title>
                        <p class="p-text-lg">Payment Information</p>
                    </v-card-title>
                    <v-card-text>
                        <p>{{ state.paymentInformation }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ordersService } from '@/api/services/order';
import { paymentService } from '@/api/services/payment';
import { useSettingsStore } from '@/api/store/settings';

import { swanpayService } from '@/api/services/swanpay';
import { settingsService } from '@/api/services/setting';
import SwanPayContainer from '@/components/SwanPayContainer.vue';

import ND from '@/components/vectors/ND.vue';

const route = useRoute();
const router = useRouter();


const settingsStore = useSettingsStore();

const state = reactive({
    order: null as any,
    loaded: false,
    paymentData: {} as any,
    paymentSession: null as any,
    paymentInformation: "" as string,
    hasCardPayments: false,
    paymentOption: null as any
});

const getPaymentInformation = () => {
    settingsService.fetch('payment_information').then((response: any) => {
        state.paymentInformation = response.value;
    }).catch((error: any) => {
        console.log(error);
    });
}

const getOrder = () => {
    settingsStore.loading = true;
    ordersService.show(+route.params.id).then((response) => {
        state.order = response.order;
        state.hasCardPayments = response.has_card_payments;
        state.paymentData.order_id = state.order.id;
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        settingsStore.loading = false;
        state.loaded = true;
    });
}

const getSession = () => {
    state.paymentSession = null;

    swanpayService.getPaymentSession(state.paymentData)
        .then((response) => {
            state.paymentSession = {
                id: response.payment_session.external_session_id,
                currency: response.payment_session.currency,
                amount: response.payment_session.amount,
                sessionData: response.payment_session.session_data
            }
        })
        .catch((error) => {
            console.error(error);

            paymentError();
        })
        .finally(() => {
            state.loaded = true;
            settingsStore.loading = false;
        });
}

const setPaymentData = (optionValue: any) => {
    state.paymentOption = null;
    state.paymentData.order_id = optionValue.order_id;
    state.paymentData.instalment_id = optionValue.instalment_id;
    state.paymentOption = optionValue;
    getSession();
}

const paymentSuccess = () => {
    state.order.status = 'processing';
    router.push({ name: 'order-confirmation', params: { id: state.order.id } });
}

const paymentError = () => {
    console.log('Payment Failed');
}

const getPaymentOptions = computed(() => {
    const options = [] as any;
    const currencySymbol = state.order.currency?.symbol ?? null;

    if (!state.order) {
        return options;
    }

    if (state.order.payment_status == "paid") {
        return options;
    }

    // Don't show instalments for supplier orders.

    if (state.order.instalments.length > 1)
    {
        state.order.instalments.forEach((instalment: any, index: number) => {
            if (instalment.payment_status == "paid" ||
                instalment.amount == 0 
            ) return;

            options.push({
                name: `instalment-${instalment.id}`,
                label: `Pay Instalment #${index+1} (${currencySymbol + instalment.price.toFixed(2)})`,
                value: { order_id: state.order.id, instalment_id: instalment.id }
            });
        });
    }

    
    options.push({
        name: "outstanding",
        label: `Pay Total Outstanding Amount (${state.order.outstanding_display})`,
        value: { order_id: state.order.id }
    });

    return options;
});

getPaymentInformation();
getOrder();
</script>

<style>
.tick-icon-outer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    margin-top: -20px;
    margin-left: 70px;
}
</style>