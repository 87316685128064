<template>
    <v-container v-if="state.document">
        <v-sheet max-width="600" class="mx-auto mb-5" v-if="state.document.status === 'complete'">
            <v-alert type="success" elevation="2" class="mx-2" color="success" dark>
                <v-row no-gutters>
                    <v-col cols="12">
                        <p class="text-h6">Form Submitted</p>
                        <p class="text-body-1">You have already submitted this form, you cannot edit it.</p>
                    </v-col>
                </v-row>
            </v-alert>
        </v-sheet>
        <v-sheet max-width="400" class="mx-auto px-3" >
            <v-form validate-on="submit lazy" :disabled="state.document.status === 'complete'">
                <div v-for="(item, index) in state.document.data.items" :key="index" class="form-group">
                    <span v-html="item.content" v-if="item.type === 'text'"></span>
                    <form-field :item="item" v-model="state.document.data.items[index].value" v-else/>
                </div>

                <div v-if="state.document.status !== 'complete'">
                    <v-btn :loading="settingsStore.loading && state.document.status !== 'complete'"
                    v-if="state.document.document_template.document.type === 'form'" 
                        :color="settingsStore.settings.secondaryColor"
                        block class="mt-2" text="Save"
                        @click="submit(false)">
                    </v-btn>
                    <v-btn v-if="state.document.document_template.document.type === 'form'" :loading="settingsStore.loading && state.document.status === 'complete'"
                    :color="settingsStore.settings.primaryColor"
                        block class="mt-2" text="Save & Submit" @click="submit(true)">
                    </v-btn>
                </div>
            </v-form>
        </v-sheet>
    </v-container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';
import { documentService } from '@/api/services/document';
import { useSettingsStore } from '@/api/store/settings';
import { useRouter } from 'vue-router';

import FormField from '@/components/SwandoolaDocBuilder/FormField.vue';

const router = useRouter();

const settingsStore = useSettingsStore();

const state = reactive({
    document: null as any,
});

const documentId = computed(() => router.currentRoute.value.params.id);

const fetchDocument = async () => {
    documentService.show(documentId.value as string).then((response) => {
        state.document = response;
    })
    .catch((error) => {
        console.log(error);
    })
    .finally(() => {
        settingsStore.loading = false;
    });
};

fetchDocument();

const checkRequiredFields = (data: any) => {
    let allRequiredFieldsValid = true;
    
    data.items.forEach((item: any) => {
        if (item.isRequired) {
            if (!item.value || item.value.trim() === "") {
                allRequiredFieldsValid = false;
                console.log(`Required field missing value: ${item.label}`);
            }
        }
    });

    return allRequiredFieldsValid;
}

const submit = async (completed: boolean) => {

    if (!checkRequiredFields(state.document.data)) {
        alert("Please fill in all required fields.");
        return;
    }
    
    settingsStore.loading = true;
    const response = await documentService.update(state.document.id, {
        data: state.document.data,
        completed: completed,
    });
    settingsStore.loading = false;
    fetchDocument();
};
</script>