import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue';
import LoginPage from '@/views/AuthComponents/LoginPage.vue';
import LogoutPage from '@/views/AuthComponents/LogoutPage.vue';
import AssociatedAccounts from '@/views/ClientComponents/AssociatedAccounts.vue';
import ProcessLogin from '@/views/AuthComponents/ProcessLogin.vue';
import AppointmentsView from '@/views/AppointmentsEvents/AppointmentsView.vue';
import AppointmentsIndex from '@/views/AppointmentsComponents/AppointmentsIndex.vue';
import FilesView from '@/views/FilesView.vue';
import FormsView from '@/views/FormsView.vue';
import HealthView from '@/views/HealthView.vue';
import NewsView from '@/views/NewsView.vue';
import OrdersView from '@/views/OrdersView.vue';
import ShopView from '@/views/ShopView.vue';
import ClinicIndex from '@/views/ShopComponents/ClinicIndex.vue';
import SuppliersIndex from '@/views/ShopComponents/SuppliersIndex.vue';
import AccountView from '@/views/AccountView.vue';
import ProductView from '@/views/ShopComponents/ItemComponents/ProductView.vue';
import SupplierProductView from '@/views/ShopComponents/ItemComponents/SupplierProductView.vue';
import ShopCartView from '@/views/ShopComponents/ShopCart/ShopCartView.vue';
import OrderCreate from '@/views/OrdersComponents/OrderCreate.vue';
import OrderConfirmation from '@/views/OrdersComponents/OrderConfirmation.vue';
import OrderView from '@/views/OrdersComponents/OrderView.vue';
import OrderPayment from '@/views/OrdersComponents/OrderPayment.vue';
import PaymentView from '@/views/PaymentView.vue';
import ClientView from '@/views/ClientView.vue';
import PersonalDetails from '@/views/ClientComponents/PersonalDetails/PersonalDetails.vue';
import MedicalDetails from '@/views/ClientComponents/MedicalDetails/MedicalDetails.vue';
import DocumentView from '@/views/DocumentsView.vue';
import DocumentViewer from '@/views/DocumentComponents/DocumentViewer.vue';
import DocumentDocuments from '@/views/DocumentComponents/DocumentDocuments.vue';
import DocumentForms from '@/views/DocumentComponents/DocumentForms.vue';
import DocumentSurveys from '@/views/DocumentComponents/DocumentSurveys/DocumentSurveys.vue';
import NotificationsView from '@/views/NotificationsView.vue';
import NotificationView from '@/views/NotificationsComponents/NotificationView.vue';
import AppointmentCreate from '@/views/AppointmentsComponents/AppointmentCreate.vue';
import ServiceView from '@/views/ShopComponents/ItemComponents/ServiceView.vue';
import BookingCreate from '@/views/AppointmentsComponents/BookingComponents/BookingCreate.vue';
import DocumentSurveyForm from "@/views/DocumentComponents/DocumentSurveys/DocumentSurveyForm.vue";
import DocumentSurveyView from "@/views/DocumentComponents/DocumentSurveys/DocumentSurveyView.vue";
import Preview from "@/views/HomePreview.vue";
import MedicalAlerts from "@/views/ClientComponents/MedicalAlerts/MedicalAlerts.vue";
import CreateEditMedicalAlerts from "@/views/ClientComponents/MedicalAlerts/CreateEditMedicalAlerts.vue";
import ViewMedicalAlert from "@/views/ClientComponents/MedicalAlerts/ViewMedicalAlert.vue";
import PackageView from "@/views/ShopComponents/ItemComponents/PackageView.vue";
import PractitionerBio from "@/views/AppointmentsComponents/BookingComponents/PractitionerBio.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home',
            showTitleBar: false,
            showBrandBar: true,
        },
        component: HomeView
    },
    {
        path: '/preview/:uuid',
        name: 'preview',
        meta: {
            title: 'Preview',
            showTitleBar: false,
            showBrandBar: true,
        },
        component: Preview
    },
    {
        path: '/appointments',
        name: 'appointments',
        meta: {
            title: 'Appointments',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: AppointmentsView
    },
    {
        path: '/appointments/confirmed',
        name: 'confirmed-appointments',
        meta: {
            title: 'Confirmed Appointments',
            showTitleBar: true,
        },
        component: AppointmentsIndex
    },
    {
        path: '/appointments/create',
        name: 'create-appointment',
        meta: {
            title: 'Create Appointments',
            showTitleBar: true,
        },
        component: AppointmentCreate
    },
    {
        path: '/files',
        name: 'files',
        meta: {
            title: 'Files',
            showTitleBar: true,
            showBrandBar: true,
        },
        component: FilesView
    },
    {
        path: '/forms',
        name: 'forms',
        meta: {
            title: 'Forms',
            showTitleBar: true,
            showBrandBar: true,
        },
        component: FormsView
    },
    {
        path: '/health-suite',
        name: 'health suite',
        meta: {
            title: 'Health Suite',
            showTitleBar: true,
        },
        component: HealthView
    },
    {
        path: '/news',
        name: 'news',
        meta: {
            title: 'News',
            showTitleBar: true,
            showBrandBar: true,
        },
        component: NewsView
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {
            title: 'Order History',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: OrdersView
    },
    {
        path: '/orders/:id',
        name: 'order-view',
        meta: {
            title: 'View Order',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: OrderView
    },
    {
        path: '/orders/confirmation/:id',
        name: 'order-confirmation',
        meta: {
            title: 'Confirmation',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: OrderConfirmation
    },
    {
        path: '/shop',
        name: 'shop',
        meta: {
            title: 'My Shop',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
            withSettingsBackground: true,
        },
        component: ShopView
    },
    {
        path: '/shop/cart',
        name: 'shopping-cart',
        meta: {
            title: 'Continue Shopping',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: ShopCartView
    },
    {
        path: '/shop/clinic/:category?',
        name: 'clinic-shop',
        meta: {
            title: 'Our Shop',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
        },
        component: ClinicIndex
    },
    {
        path: '/shop/naturial-dispensary', //add route for multi-clinic
        name: 'natural-dispensary',
        meta: {
            title: 'Natural Dispensary',
            showTitleBar: true,
            colorTitleBar: "#3A5485",
            textTitleBar: "#FFF",
            showBrandBar: false,
            showCart: true,
        },
        component: SuppliersIndex
    },
    {
        path: '/my-account',
        name: 'my-account',
        meta: {
            title: 'My Account',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: AccountView
    },
    {
        path: '/shop/product/:id/:type?',
        name: 'shop-product',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            colorTitleBar: "",
            textTitleBar: "#FFF",
            showBrandBar: false,
            showCart: true,
        },
        beforeEnter: (to, from, next) => {
            if (to.params.type === 'natural-dispensary') {
                to.meta.colorTitleBar = "#3A5485";
            }

            next();
        },
        component: ProductView,
    },
    {
        path: '/shop/service/:id',
        name: 'shop-service',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
        },
        component: ServiceView
    },
    {
        path: '/shop/package/:id',
        name: 'shop-package',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
        },
        component: PackageView
    },
    {
        path: '/shop/service/:id/book/:practitionerId?',
        name: 'book-service',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
        },
        component: BookingCreate
    },
    {
        path: '/practitioner/:serviceId/:practitionerId?/:view?',
        name: 'practitioner-bio',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            showBrandBar: false,
            showCart: true,
        },
        component: PractitionerBio
    },
    {
        path: '/shop/naturial-dispensary/product/:id',
        name: 'supplier-product',
        meta: {
            title: 'Back to list',
            showTitleBar: true,
            colorTitleBar: "#3A5485",
            textTitleBar: "#FFF",
            showBrandBar: false,
            showCart: true,
        },
        component: SupplierProductView
    },
    {
        path: '/orders/create',
        name: 'order-create',
        meta: {
            title: 'Back to cart',
            showTitleBar: true,
            showBrandBar: false,
            showCart: false,
        },
        component: OrderCreate
    },
    {
        path: '/orders/payment/:id',
        name: 'order-payment',
        meta: {
            title: 'Make A Payment',
            showTitleBar: true,
            textTitleBar: "#FFF",
            showBrandBar: false,
            showCart: false,
        },
        component: OrderPayment
    },
    {
        path: '/payment/:token',
        name: 'payment',
        meta: {
            title: 'Payment',
            showTitleBar: false,
            showBrandBar: false,
        },
        component: PaymentView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/login/:token',
        name: 'process-login',
        component: ProcessLogin
    },
    {
        path: '/logout',
        component: LogoutPage
    },
    {
        path: '/associated-accounts',
        name: 'associated-accounts',
        component: AssociatedAccounts
    },
    {
        path: '/client',
        name: 'client',
        meta: {
            title: 'Your Profile',
            showTitleBar: true,
            showBrandBar: false,
            withSettingsBackground: true,
        },
        component: ClientView
    },
    {
        path: '/client/details',
        name: 'client-details',
        meta: {
            title: 'Personal Details',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: PersonalDetails
    },
    {
        path: '/medical/details',
        name: 'medical-details',
        meta: {
            title: 'Medical Details',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: MedicalDetails
    },
    {
        path: '/documents',
        name: 'documents',
        meta: {
            title: 'My Files',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: DocumentView
    },
    {
        path: '/documents/:id',
        name: 'document-viewer',
        meta: {
            title: 'Document Viewer',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: DocumentViewer
    },
    {
        path: '/documents/documents',
        name: 'document-documents',
        meta: {
            title: 'Documents',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: DocumentDocuments
    },
    {
        path: '/documents/forms',
        name: 'document-forms',
        meta: {
            title: 'Forms',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: DocumentForms
    },
    {
        path: '/documents/surveys',
        name: 'document-surveys',
        meta: {
            title: 'Surveys',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: DocumentSurveys
    },
    {
        path: '/documents/surveys/:surveyId/:clientSurveyId',
        name: 'document-survey-form',
        meta: {
            title: 'Surveys',
            showTitleBar: false,
            showBrandBar: false,
        },
        component: DocumentSurveyForm
    },
    {
        path: '/documents/surveys/:id/:surveyName',
        name: 'document-survey-view',
        meta: {
            title: 'Surveys',
            showTitleBar: false,
            showBrandBar: false,
        },
        component: DocumentSurveyView
    },
    {
        path: '/medical-alerts',
        name: 'medical-alerts',
        meta: {
            title: 'Medical Alerts',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: MedicalAlerts
    },
    {
        path: '/medical-alerts/:action/:id?',
        name: 'create-edit-medical-alerts',
        meta: {
            title: 'Medical Alert',
            showTitleBar: true,
            showBrandBar: false,
            dob: '',
        },
        component: CreateEditMedicalAlerts,
    },
    {
        path: '/medical-alerts/view/:id?',
        name: 'view-medical-alert',
        meta: {
            title: 'Medical Alert',
            showTitleBar: true,
            showBrandBar: false,
            dob: '',
        },
        component: ViewMedicalAlert,
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: {
            title: 'Notifications',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: NotificationsView
    },
    {
        path: '/notifications/:id',
        name: 'view-notification',
        meta: {
            title: 'View Notification',
            showTitleBar: true,
            showBrandBar: false,
        },
        component: NotificationView
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
