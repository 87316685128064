<template>
  <v-card class="card-container pa-4 d-flex align-center ga-4">
    <div v-if="getAvatar" class="position-relative" @click="emit('infoSelected')">
      <v-sheet class="avatar-container">
        <div v-if="getAvatar?.image" :style="{ backgroundImage: `url(${getAvatar.image})` }"
             class="avatar-container__image"></div>
        <div v-else class="avatar-container__initials primary-background-button-color">{{ getAvatar?.initials }}</div>
      </v-sheet>
      <div class="info">
        <Icon color="primaryButtonColor" height="22" icon="info-icon" width="22"/>
      </div>
    </div>

    <div class="w-100 d-flex justify-space-between">
      <div>
        <p class="font-size-14-600">
          {{ practitioner?.first_name }} {{ practitioner?.last_name }}
        </p>
        <p class="font-size-12-400 ">
          {{ practitioner?.role_legacy }}
        </p>
      </div>

      <v-btn
          :class="isPractitionerSelected ? 'secondary-button' : 'transparent secondary-button-color'"
          class="b-round-20 outline-btn"
          elevation="0"
          height="32px"
          variant="outlined"
          width="82px"
          x-small
          @click="emit('select')"
      >
        Select
      </v-btn>

    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, defineEmits, defineProps} from "vue";
import Icon from "@/components/Icons/Icon.vue";

const emit = defineEmits(['select', 'infoSelected']);

const props = defineProps({
  practitioner: {
    type: Object,
  },
  isPractitionerSelected: {
    type: Boolean,
    default: false,
  },
  borderColor: {
    type: String,
    default: '#F0F0F0',
  },
});

const getAvatar = computed(() => {
  const creator = props.practitioner;
  if (creator) {
    const firstName = creator.first_name ?? '';
    const lastName = creator.last_name ?? '';

    return creator.image
        ? {image: creator.image.file?.file_path ?? '', initials: null}
        : {image: null, initials: (firstName[0] + (lastName[0] || '')).toUpperCase()};
  }
  return null;
});
</script>

<style lang="scss" scoped>
.card-container {
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid v-bind(borderColor);

  .avatar-container {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    margin: auto;

    &__image {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 12px;
      border-radius: 50%;
    }
  }
}

.info {
  position: absolute;
  width: 22px;
  height: 24.62px;
  right: 0;
  top: 0;
  z-index: 2;
}
</style>