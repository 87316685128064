<template>
  <v-card class="pa-4 border-rounded">
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
            :class="state.modelValue.method == 'in_person' ? 'text-white' : ''"
            :color="state.modelValue.method == 'in_person' ? state.color : ''"
            :disabled="!isServiceMethodAvailable.in_person"
            :variant="state.modelValue.method == 'in_person' ? 'flat' : 'text'"
            class="b-round-20"
            @click="state.modelValue.method = 'in_person';emit('update:modelValue', state.modelValue)">
          
          <span
              :class="dark && state.modelValue.method == 'in_person'  ? 'text-white' : ''"
              class="font-size-12-600 text-capitalize d-flex align-center ga-1"
          >
            In person
            <Icon :color="state.modelValue.method == 'in_person'? 'white' : iconColor" icon="location-icon"/>
          </span>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
            :class="state.modelValue.method == 'video' ? 'text-white' : ''"
            :color="state.modelValue.method == 'video' ? state.color : ''"
            :disabled="!isServiceMethodAvailable.video"
            :variant="state.modelValue.method == 'video' ? 'flat' : 'text'"
            class="b-round-20"
            @click="state.modelValue.method = 'video';emit('update:modelValue', state.modelValue)">
          <span
              :class="dark && state.modelValue.method == 'video'  ? 'text-white' : ''"
              class="font-size-12-600 text-capitalize d-flex align-center ga-1"
          >
            Video
            <Icon :color="state.modelValue.method == 'video'? 'white' : iconColor" icon="video-icon"/>
          </span>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
            :class="state.modelValue.method == 'phone' ? 'text-white' : ''"
            :color="state.modelValue.method == 'phone' ? state.color : ''"
            :disabled="!isServiceMethodAvailable.phone"
            :variant="state.modelValue.method == 'phone' ? 'flat' : 'text'"
            class="b-round-20"
            @click="state.modelValue.method = 'phone';emit('update:modelValue', state.modelValue)">
          <span
              :class="dark && state.modelValue.method == 'phone'  ? 'text-white' : ''"
              class="font-size-12-600 text-capitalize d-flex align-center ga-1"
          >
            Phone call
            <Icon :color="state.modelValue.method == 'phone'? 'white' : iconColor" icon="phone-icon"/>
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import {defineProps, defineEmits, reactive} from 'vue';
import Icon from "@/components/Icons/Icon.vue";

const props = defineProps(['modelValue', 'color', 'iconColor', 'isServiceMethodAvailable'])
const emit = defineEmits(['update:modelValue']);

const state = reactive({
  modelValue: props.modelValue,
  color: props.color,
});
</script>

<style lang="scss" scoped>
.border-rounded {
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #70E591
}
</style>

<style>
.v-picker__header {
  display: none !important;
}

.v-picker-title {
  display: none !important;
}
</style>