<template>
  <v-container class="px-0 pt-0 mb-12 position-relative" style="background-color:#F0F0F0; min-height:100vh">
    <v-row class="mt-0 mb-2 py-5 text-center" no-gutters style="background-color:#FFF">
      <v-col cols="12">
        <SearchFilter @update:modelValue="(val: string) => state.search = val"/>
      </v-col>
      <v-col class="d-flex px-3" cols="12">
        <v-col class="mt-1 position-relative left-filter-text" @click="()=>state.filterSelected = 'sort'">
          <ButtonFilterSelect
              v-model="sortTabSelectedValue"
              :is-tab-selected="state.filterSelected === 'sort'"
              :items="sortValues"
              :multiple="false"
              :text="sortTabSelectedValue"
              icon="sort-icon"
              single-line
          />
        </v-col>
        <v-col class="mt-1 position-relative left-filter-text" @click="()=> state.filterSelected = 'filter'">
          <ButtonFilterSelect
              v-model="filterTabSelectedValue"
              :is-tab-selected="state.filterSelected === 'filter'"
              :items="filterValues"
              :multiple="false"
              :text="filterTabSelectedValue"
              single-line
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="text-center py-0" no-gutters>
      <v-col class="d-flex px-4" cols="12">
        <v-col>
          <ButtonBase
              :custom-class="['transparent text-black', {'secondary-button text-white': state.tab === 'upcoming'}]"
              text="Upcoming"
              width="100%"
              @click="() => state.tab = 'upcoming'"
          />
        </v-col>
        <v-col>
          <ButtonBase
              :custom-class="['transparent text-black', {'secondary-button text-white': state.tab === 'past'}]"
              text="Past"
              width="100%"
              @click="() => state.tab = 'past'"
          />
        </v-col>
        <v-col>
          <ButtonBase
              :custom-class="['transparent text-black', {'secondary-button text-white': state.tab === 'cancelled'}]"
              text="Cancelled"
              width="100%"
              @click="() => state.tab = 'cancelled'"
          />
        </v-col>
      </v-col>
    </v-row>

    <section v-if="!settingsStore.loading">
      <AppointmentsCard
          v-for="consultation in state.consultations"
          :key="consultation.id"
          :consultation="consultation"
          :tab="state.tab"
      />
    </section>

    <v-card v-if="state.consultations.length == 0" class="mt-4 mx-4 mb-3" height="50vh">
      <v-row class="my-2 py-1" no-gutters>
        <v-col class="my-1 px-10 text-center" cols="12">
          <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No {{ state.tab }} Found</v-alert>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="state.pagination.links.next" class="text-center">
      <v-btn
          :ripple="false"
          class="load-more text-capitalize font-size-12-600"
          variant="plain"
          @click="seeMore"
      >
        Load More
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed, shallowRef, watch} from 'vue';
import {consultationService} from '@/api/services/consultation';
import {useSettingsStore} from '@/api/store/settings';
import {filterValues, sortValues} from '@/views/AppointmentsEvents/Helpers';
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";
import SearchFilter from "@/components/custom/SearchFilter.vue";
import debounce from 'lodash/debounce';
import AppointmentsCard from "@/views/AppointmentsEvents/AppointmentsCard.vue";
import ButtonBase from "@/components/custom/ButtonBase.vue";

const settingsStore = useSettingsStore();

// Data
const state = reactive({
  consultations: [] as any[],
  pagination: {
    meta: {},
    links: [],
  },
  search: '' as string,
  filterSelected: 'filter' as string,
  tab: 'upcoming',
});

// Sort and Filter state
const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})
const filterTabSelectedValue = shallowRef({
  name: 'Filter',
  label: 'Filter'
})

// Pagination state
const page = shallowRef(1)

// Computed
const filterQueryString = computed(() => {
  let filter = {
    sorting: '',
    search: state.search,
    page: page.value,
    tab: state.tab,
    searching: 'name'
  };

  let sortOrder = sortTabSelectedValue.value.name === 'Sort' ? 'desc' : sortTabSelectedValue.value.name;

  let filterValue = filterTabSelectedValue.value.name === 'Filter' ? 'name' : filterTabSelectedValue.value.name;

  if (filterTabSelectedValue.value.name === 'Filter' && sortTabSelectedValue.value.name === 'Sort') {
    filter.sorting = 'id,desc';
  } else {
    if (filterValue === 'upcoming' || filterValue === 'past' || filterValue === 'canceled') {
      filter.tab = filterValue;
      filter.sorting = `id,${sortOrder}`;
    } else {
      filter.sorting = `${filterValue},${sortOrder}`;
    }
  }

  return filter;
});

const getConsultations = () => {
  settingsStore.loading = true;
  consultationService.index(filterQueryString.value).then((response) => {
    const withEvents = response.data.filter(item => item.event);
    const withoutEvents = response.data.filter(item => !item.event);

    state.consultations = [...withEvents, ...withoutEvents];
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    settingsStore.loading = false;
  });
}

// Debounced to fetch getMedicalAlerts
const debouncedGetOrders = debounce(getConsultations, 300);

const resetState = () => {
  page.value = 1;
  state.consultations = [];
}

const seeMore = () => {
  if (state.pagination.links.next) {
    page.value++;
    getConsultations();
  }
}

watch(() => [filterTabSelectedValue.value, sortTabSelectedValue.value, state.search, state.tab], () => {
  resetState();
  debouncedGetOrders();
});

getConsultations();
</script>

<style lang="scss" scoped>
:deep(.add-new-circle-icon) {
  margin-right: 3px;
}

.left-filter-text {
  :deep(.v-btn) {
    justify-content: left;
  }
}
</style>