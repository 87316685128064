<template>
  <v-container class="px-0 pt-0 pb-12 position-relative" style="background-color:#F0F0F0; min-height:100vh">
    <v-row class="mt-0 mb-2 py-5 text-center" no-gutters>
      <v-col class="text-left pl-2" cols="12">
        <v-card-title class="font-size-18-600">
          Recent Documents
        </v-card-title>
      </v-col>

      <v-col cols="12">
        <SearchFilter @update:modelValue="(val: string) => state.search = val"/>
      </v-col>
      <v-col class="d-flex px-3" cols="12">
        <v-col class="mt-1 position-relative left-filter-text" @click="()=>state.filterSelected = 'sort'">
          <ButtonFilterSelect
              v-model="sortTabSelectedValue"
              :is-tab-selected="isSortTabSelected"
              :items="sortValues"
              :multiple="false"
              :text="sortTabSelectedValue"
              icon="sort-icon"
              single-line
          />
        </v-col>
        <v-col class="mt-1 position-relative left-filter-text" @click="()=> state.filterSelected = 'filter'">
          <ButtonFilterSelect
              v-model="filterTabSelectedValue"
              :is-tab-selected="isFilterTabSelected"
              :items="filters"
              :multiple="false"
              :text="filterTabSelectedValue"
              single-line
          />
        </v-col>
      </v-col>
    </v-row>

    <section v-for="document in state.items" :key="document.id" class="mt-4 mx-4 mb-3">
      <v-card v-if="document.file_resource && !settingsStore.loading"
              class="mt-2 mx-2 mb-2 pa-4 border-rounded">
        <v-row class="my-0" no-gutters>
          <v-col cols="12">
            <v-row>
              <v-col class="px-3">

                <div class="d-flex ga-2">
                  <p v-if="document.viewed_by_client == 0" class="mt-2 unread"></p>

                  <div>
                    <p :class="{'font-size-16-600':	document.viewed_by_client == 0, 'font-size-14-400': 	document.viewed_by_client == 1}">
                      {{ document.file_resource.title }}
                    </p>

                    <p class="font-size-10-400 font-grey-dark" style="margin-top: 2px">
                      {{ moment(document.created_at).format('DD MMM YYYY') }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col class="text-end px-3">
                <v-btn
                    class="text-white b-round-20 outline-btn white-btn"
                    elevation="0"
                    height="32px"
                    x-small
                    @click="openFile(document)"
                >
                  {{ document?.file_resource?.kind == 'plan' ? 'View' : 'Download' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </section>

    <v-card v-if="state.items.length == 0" class="mt-4 mx-4 mb-3" height="50vh">
      <v-row class="my-2 py-1" no-gutters>
        <v-col class="my-1 px-10 text-center" cols="12">
          <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No Documents Available</v-alert>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="state.pagination.next_page_url" class="text-center">
      <v-btn
          :ripple="false"
          class="load-more text-capitalize font-size-12-600"
          variant="plain"
          @click="seeMore"
      >
        Load More
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, shallowRef, watch} from 'vue';
import {filesService} from '@/api/services/file';
import {useSettingsStore} from '@/api/store/settings';
import {sortValues} from "@/views/OrdersComponents/Helpers";
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";
import SearchFilter from "@/components/custom/SearchFilter.vue";
import debounce from 'lodash/debounce';
import moment from "moment/moment";
import {useRouter} from "vue-router";

const settingsStore = useSettingsStore();
const router = useRouter();

const setAppColor = settingsStore.settings.buttonColor2

const filters = [
  {
    name: 'Filter',
    label: 'All'
  },
  {
    name: 'created_at',
    label: 'Date'
  },
  {
    name: 'id',
    label: 'Id'
  },
]

// Data
const state = reactive({
  items: [] as any[],
  pagination: {
    meta: {},
    links: [],
    next_page_url: null,
  },
  search: '' as string,
  filterSelected: 'filter' as string,
});

const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})
const filterTabSelectedValue = shallowRef({
  name: 'Filter',
  label: 'Filter'
})
const page = shallowRef(1)

// Computed
const isFilterTabSelected = computed(() => {
  return state.filterSelected === 'filter'
});

const isSortTabSelected = computed(() => {
  return state.filterSelected === 'sort'
});

const filterQueryString = computed(() => {
  let filter = {
    sorting: '',
    search: state.search,
    page: page.value,
    kind: 'documents',
  };

  let sortOrder = sortTabSelectedValue.value.name === 'Sort' ? 'desc' : sortTabSelectedValue.value.name;

  let filterValue = filterTabSelectedValue.value.name === 'Filter' ? 'title' : filterTabSelectedValue.value.name;

  if (filterTabSelectedValue.value.name === 'Filter' && sortTabSelectedValue.value.name === 'Sort') {
    filter.sorting = 'id,desc';
  } else {
    filter.sorting = `${filterValue},${sortOrder}`;
  }

  return filter;
});

const getFiles = () => {
  settingsStore.loading = true;
  filesService.index(filterQueryString.value).then((response) => {
    state.items.push(...response.data)
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    state.pagination.next_page_url = response.links.next;
    settingsStore.loading = false;
  });
}

// Debounced to fetch getMedicalAlerts
const debouncedGetOrders = debounce(getFiles, 300);

const resetState = () => {
  page.value = 1;
  state.items = [];
}

const seeMore = () => {
  if (state.pagination.next_page_url) {
    page.value++;
    getFiles();
  }
}

const openFile = (file: any) => {

  if (file.file_resource?.kind == 'plan') {
    router.push({name: 'document-viewer', params: {id: file.file_resource.model_id}});
  } else {
    // Handle file through browser window - may require FileSystem integration
    window.open(file.file_resource?.file.file_path, '_blank');
  }

  if (file.viewed_by_client == 0) {
    filesService.viewedByClient(file.client_id, file.file_resource_id).then(() => {
      state.items = state.items.map((item) => {
        if (item.id == file.id) {
          item.viewed_by_client = 1;
        }
        return item;
      })
    })
  }

}

watch(() => [filterTabSelectedValue.value, sortTabSelectedValue.value, state.search], () => {
  resetState();
  debouncedGetOrders();
});

getFiles();
</script>

<style lang="scss" scoped>
:deep(.add-new-circle-icon) {
  margin-right: 3px;
}

.left-filter-text {
  :deep(.v-btn) {
    justify-content: left;
  }
}

.load-more {
  padding: 10px;
  text-align: center;
  margin: 10px auto 30px auto;
  width: 200px;
  color: v-bind(setAppColor);
  text-decoration: underline;
  text-underline-offset: 2px;
  opacity: 1;
}

.left-filter-text, .right-filter-text {
  :deep(.v-btn) {
    background: white;
  }
}

.unread {
  background: #1EBCD5;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.border-rounded {
  border-radius: 8px;
  border: 1px solid #E0E4E9;
  box-shadow: none;
}
</style>