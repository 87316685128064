<template>
  <section v-if="!isShipping" :class="{'border-bottom': !isLastItem}" class="pt-5">
    <div class="card-item-details">
      <v-sheet v-if="getImage" class="logo-container m-0">
        <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
      </v-sheet>

      <v-sheet v-else :class="{'secondary-background-button-color': !item?.supplier }" class="icon-container">
        <Icon :icon="getIcon"/>
      </v-sheet>

      <v-card-text class="pa-0">
        <div class="d-flex align-center justify-space-between">
          <p class="font-size-12-600">{{ getTitle }}</p>
          <div :class="{'disabled': disabled}" @click="emit('delete')">
            <Icon icon="trash-icon"/>
          </div>
        </div>

        <div :class="{'justify-space-between mb-2': justifyEnd}" class="d-flex justify-end align-center mt-1 ga-2">
          <slot></slot>
          <p class="font-size-14-600">{{ getPrice }}</p>
        </div>

        <div v-if="getInstalments.length > 0" class="mt-1">
          <p class="font-size-10-600">Instalments (1/{{ getInstalments.length }})</p>
          <div class="d-flex justify-space-between font-size-10-400 font-danger mt-1">
            <p>Due Now</p>
            <p class="font-size-12-400">
              {{ item.currency }}{{ getFirstInstalment.amount }}
            </p>
          </div>
        </div>
      </v-card-text>
    </div>

    <v-card-text class="px-0 pb-2 d-flex ga-4">
      <v-col v-if="item?.product?.services?.length > 0" cols="4">
        <div class="font-black font-size-12-400">
          <p class="font-size-12-600">Services Included</p>
          <p v-for="(service, index) in item?.product?.services" :key="`service-${index}`"
             class="my-2 font-size-12-400">
            {{ service.name }}
          </p>
        </div>
      </v-col>

      <v-col v-if="getDescription" class="pr-3" cols="8">
        <div ref="descriptionContainer" class="three-line-ellipsis font-grey-dark font-size-12-400 line-height-18">
          "{{ getDescription }}"
        </div>

        <div v-if="isTextOverflowing || showMore" class="text-right pr-2">
          <v-btn
              :ripple="false"
              class="primary-underline-button text-capitalize font-size-12-600 pa-0"
              variant="plain"
              @click="toggleText"
          >
            {{ showMore ? 'Show Less' : 'Show More' }}
          </v-btn>
        </div>
      </v-col>
    </v-card-text>
  </section>
</template>

<script lang="ts" setup>
import {defineProps, computed, ref, onMounted} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import {defineEmits} from 'vue';
import minBy from 'lodash/minBy';
import isEmpty from 'lodash/isEmpty';

const emit = defineEmits(['delete']);

const props = defineProps({
  item: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isLastItem: {
    type: Boolean,
    default: false
  },
  justifyEnd: {
    type: Boolean,
    default: false
  }
});

const showMore = ref(false);
const isTextOverflowing = ref(false);
const descriptionContainer = ref<HTMLElement | null>(null);

const toggleText = () => {
  showMore.value = !showMore.value;

  // Toggle full text display when 'Show More' is clicked
  if (descriptionContainer.value) {
    if (showMore.value) {
      descriptionContainer.value.style.webkitLineClamp = 'unset';
    } else {
      descriptionContainer.value.style.webkitLineClamp = '3';
    }
  }
};

const getTitle = computed(() => {
  if (props.item?.product) {
    return props.item.product.name;
  }
  return props.item?.name;
});

const getImage = computed(() => {
  if (props.item?.product && props.item?.product?.image !== undefined) {
    return props.item?.product?.image?.file?.file_path ?? null;
  }
  return null;
});

const getIcon = computed(() => {
  const type = props.item.product?.type ?? null;
  if (type === 'clinic_service' || type === 'service') {
    return 'service-icon';
  }
  return 'package-icon';
});

const getPrice = computed(() => {
  return props.item.price_display ?? '-';
});

const isShipping = computed(() => {
  return props.item.product && props.item.product.type === 'shipping_method';
});

const getDescription = computed(() => {
  return props.item.product.description ?? '';
});

const getInstalments = computed(() => {
  if (props.item.product?.instalments === undefined || props.item.product?.instalments.length === 0) {
    return [];
  }
  return props.item.product.instalments;
});

const getFirstInstalment = computed(() => {
  if (!isEmpty(getInstalments.value)) {
    // Use lodash's minBy to get the instalment with the lowest "days"
    return minBy(getInstalments.value, 'days');
  }
  return null;
});

// Check for text overflow when the component is mounted
onMounted(() => {
  if (descriptionContainer.value) {
    const element = descriptionContainer.value;
    isTextOverflowing.value = element.scrollHeight > element.clientHeight;
  }
});
</script>

<style lang="scss" scoped>
.card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .logo-container {
    width: 126px;
    height: 100px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.icon-container {
  border-radius: 5px;
  width: 126px;
  height: 100px;
  background: #3A5485;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supplier {
  background: #3A5485;
  color: white;
  padding: 2px 6px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.disabled {
  pointer-events: none;
}

.font {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.line-height-18 {
  line-height: 18px;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-bottom {
  border-bottom: 1px solid #F0F0F0;
}
</style>