<template>
  <v-card class="mt-2 mx-2 mb-2 pa-4 border-rounded">
    <div class="d-flex align-center justify-space-between">
      <div>
        <div class="font-size-12-600 primary-color">My selection</div>
        <h3 class="font-size-16-600 mt-1">{{ firstLetterUppercase(service?.name) }}</h3>
        <div v-if="service?.package_name" class="font-size-10-600">
          ({{ firstLetterUppercase(service?.package_name) }})
        </div>
        <div class="font-size-12-400 mt-1">
          {{ getDurations }} <span class="font-success">|</span> {{ service?.price_display }}
        </div>
      </div>

      <div class="d-flex align-center ga-4">
        <div v-if="getAvatar">
          <v-sheet class="avatar-container">
            <div v-if="getAvatar?.image"
                 :style="{ backgroundImage: `url(${getAvatar.image})` }"
                 class="avatar-container__image"></div>
            <div v-else class="avatar-container__initials primary-background-button-color">
              {{ getAvatar?.initials }}
            </div>
          </v-sheet>
        </div>

        <div v-if="booking.method && tab.name === 'Date/Method' || tab.name === 'Details'" class="font-size-12-600">
          <Icon
              v-if="booking.method === 'in_person'"
              :color="settingsStore.settings.primaryColor"
              icon="location-icon"
          />
          <Icon
              v-if="booking.method === 'phone'"
              :color="settingsStore.settings.primaryColor"
              icon="phone-icon"
          />
          <Icon
              v-if="booking.method === 'video'"
              :color="settingsStore.settings.primaryColor"
              icon="video-icon"
          />

          <div v-if=" booking.date">
            <p>{{ booking.time }}</p>
            <p>{{ moment(booking.date).format('D MMM YYYY') }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import {firstLetterUppercase} from "@/components/Helpers/Utils";
import moment from "moment/moment";
import Icon from "@/components/Icons/Icon.vue";
import {computed, defineProps} from 'vue';

const props = defineProps({
  service: {
    type: Object,
  },
  booking: {
    type: Object,
  },
  settingsStore: {
    type: Object,
  },
  tab: {
    type: String,
  },
})

const getAvatar = computed(() => {
  const creator = props.booking.practitioner;
  if (creator) {
    const firstName = creator.first_name ?? '';
    const lastName = creator.last_name ?? '';

    return creator.image
        ? {image: creator.image.file?.file_path ?? '', initials: null}
        : {image: null, initials: (firstName[0] + (lastName[0] || '')).toUpperCase()};
  }
  return null;
});

const getDurations = computed(() => {
  return props.service?.booking_options?.duration
      ? `${props.service.booking_options.duration} min${props.service.booking_options.duration > 1 ? 's' : ''}`
      : 'Duration not specified';
});
</script>

<style lang="scss" scoped>
.border-rounded {
  border-radius: 8px;
  box-shadow: none;
}

.avatar-container {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: auto;

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
  }
}
</style>