<template>
  <v-container v-if="state.service" class="ma-0 pa-0 position-relative border-radius">
    <BookingHeader :booking="state.booking" :service="state.service" :settings-store="settingsStore" :tab="state.tab"/>
    <ButtonTab
        v-model="state.tab"
        :colour="settingsStore.settings.primaryColor"
        :dark="true"
        :tabs="[
              { 
                name: 'Practitioner',
                icon: 'person-practitioner-icon',
                colour: 'white',
                disabled: false            
              }, 
              { 
                name: 'Date/Method',
                icon: 'calendar-icon',
                colour: 'white',
                disabled: !state.booking.practitioner          
              }, 
              {
                name: 'Details',
                icon: `${state.tab.name === 'Details'? 'info-icon-white' : 'info-icon'}`,
                colour: 'transparent',
                disabled: !state.booking.practitioner || !state.booking.date || !state.booking.time               
              }
          ]"
        bg-colour="rgb(245, 245, 245)"
    />

    <v-sheet :style="{backgroundColor: state.tab.name !== 'Details'? '#f5f5f5' : 'white'}" class="pa-3" width="100%">
      <v-window v-model="state.tab.name" class="pa-2" style=" border-radius: 10px;">
        <v-window-item value="Practitioner">
          <PractitionerPage
              :border-color="state.booking.practitioner ? settingsStore.settings.secondaryColor : '#F0F0F0'"
              :is-practitioner-selected="state.booking.practitioner === state.service.creator"
              :practitioner="state.service.creator"
              @infoSelected="() => router.push({name: 'practitioner-bio', params:{ 
                serviceId: route.params.id,
                practitionerId: state.booking.practitioner?.id
              }})"
              @select="toggleSelectedPractitioner"
          ></PractitionerPage>
        </v-window-item>

        <v-window-item class="mb-14" value="Date/Method">
          <BookingMethod
              v-model="state.booking"
              :color="settingsStore.settings.secondaryColor"
              :icon-color="settingsStore.settings.primaryColor"
              :is-service-method-available="isServiceMethodAvailable"
              @update:modelValue="handleMethodChange"
          />
          <v-spacer class="mt-2"></v-spacer>

          <div class="date-picker-container border-radius">
            <v-date-picker
                v-model="state.booking.date"
                :allowed-dates="allowedDates"
                :color="settingsStore.settings.secondaryColor"
                :hide-actions="true"
                :min="state.currentDate"
                style="width:100%"
                @update:modelValue="handleDateSelect"
                @update:month="handleMonthChange"
                @update:year="handleYearChange"
            />
            <time-picker
                v-if="state.availableTimes && state.availableTimes.length > 0"
                v-model="state.booking.time"
                :availableTimeslots="state.availableTimes"
                :color="settingsStore.settings.secondaryColor"
                :highlightColor="settingsStore.settings.secondaryColor"
                @navigate="handleTimeNavigation"
            />
          </div>

          <section class="pt-3">
            <ButtonFixed
                :disabled="!state.booking.date || !state.booking.time"
                custom-class="text-white primary-button confirm-button"
                text="Confirm"
                width="100%"
                @click="state.tab.name = 'Details'"
            />
          </section>
        </v-window-item>

        <v-window-item style="background-color: red;" value="Details">
          <DetailsPage :package-service-id="route.query.packageServiceId" :state="state" :user-store="userStore"/>
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed, watch, inject, onMounted, nextTick} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {servicesService} from '@/api/services/service';
import {useSettingsStore} from '@/api/store/settings';
import {useUserStore} from '@/api/store/user';
import {useDate} from 'vuetify'
import BookingMethod from '@/components/custom/BookingMethod.vue';
import ButtonTab from '@/components/custom/ButtonTab.vue';
import TimePicker from '@/components/custom/TimePicker.vue';

import moment from 'moment';
import 'moment-timezone';
import PractitionerPage from "@/views/AppointmentsComponents/BookingComponents/BookingPages/PractitionerPage.vue";
import BookingHeader from "@/views/AppointmentsComponents/BookingComponents/BookingPages/BookingHeader.vue";
import DetailsPage from "@/views/AppointmentsComponents/BookingComponents/BookingPages/DetailsPage.vue";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";

const router = useRouter();
const route = useRoute();

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const snackbar: any = inject('snackbar');

const date = useDate()

const state = reactive({
  service: null as any | null,
  tab: {name: "Practitioner"} as any,
  booking: {
    method: "" as string,
    date: moment(date).toDate() as any | null,
    time: null as any | null,
    practitioner: null as any | null,
    termsAgreed: true as boolean,
  },
  currentDate: moment(date).toDate(),
  availableDays: [] as any[],
  availableTimes: [] as any[],
  creatingBooking: false as boolean,
  pickerState: {
    currentYear: moment().year(),
    currentMonth: moment().month()
  }
});
// Methods
const loadService = async () => {
  await servicesService.getService(route.params.id, route.query.packageServiceId).then(response => {

    if (response.package) {

      let clinicService = response.package.services.filter((item) => {
        return route.params.id == item.clinic_service_id;
      })

      const firstServiceWithinPackage = clinicService[0];

      state.service = {
        ...firstServiceWithinPackage,
        package_name: response.package.name,
        booking_options: firstServiceWithinPackage.service?.booking_options,
        creator: firstServiceWithinPackage.service?.creator,
        price_display: `${response.package.currency.symbol}${response.package.price}`,
      };

    } else {
      state.service = response;
    }

    state.booking.method = firstAllowedMethod.value;
  });

  if (route.params.practitionerId) {
    state.booking.practitioner = state.service.creator;
    state.tab.name = 'Date/Method';
  }
};

const loadAvailableTimes = async () => {
  const date = state.booking.date;
  let serviceId = state.service.id;
  const method = state.booking.method;

  if (state.service?.service) {
    serviceId = state.service.service.id;
  }

  if (!date || !serviceId || !method) {
    snackbar.errorSnackbar("Please select a date, service, and method");
    return;
  }

  try {
    const formattedDate = moment(date).format('DD/MM/YY');
    const response = await servicesService.getServiceAvailability(serviceId, {
      date: formattedDate,
      method: method
    });

    state.availableDays = response.data;
    const formattedDateKey = moment(date).format('YYYY-MM-DD');
    if (state.availableDays[formattedDateKey]) {
      state.availableTimes = state.availableDays[formattedDateKey].slots;
    } else {
      state.availableTimes = [];
    }
  } catch (err) {
    console.error(err);
    snackbar.errorSnackbar("Failed to load availability");
  }
};

const allowedDates = (date) => {
  if (!state.availableDays) return false;

  // Format date to match backend format (YYYY-MM-DD)
  const formattedDate = moment(date).format('YYYY-MM-DD');

  // Check if the date exists in availableDays
  return state.availableDays[formattedDate] !== undefined;
};

const toggleSelectedPractitioner = () => {
  if (state.booking.practitioner === state.service.creator) {
    state.booking.practitioner = null;
  } else {
    state.booking.practitioner = state.service.creator;
    state.tab.name = 'Date/Method';
  }
};

// Watchers
watch(() => state.booking.date, (newDate) => {
  if (newDate) {
    const formattedDate = moment(newDate).format('YYYY-MM-DD');

    if (state.availableDays[formattedDate]) {
      state.availableTimes = state.availableDays[formattedDate].slots;
    }
  } else {
    state.availableTimes = []
  }
}, {immediate: true});

watch(() => state.booking.method, () => {
  if (state.booking.date) {
    loadAvailableTimes();
  }
});

watch(() => state.tab.name, () => {
  if (state.tab.name === 'Date/Method' || state.tab.name === 'Details') {
    nextTick(() => {
      const backArrow = document.querySelector('.back-arrow');

      setTimeout(() => {
        if (backArrow) {
          backArrow.style.display = 'none';
        }
      }, 100);
    });
  } else {
    nextTick(() => {
      const backArrow = document.querySelector('.back-arrow');

      setTimeout(() => {
        if (backArrow) {
          backArrow.style.display = 'block';
        }
      }, 100);
    });
  }
});

// Computed
/**
 * Returns the first allowed booking method for the service
 * and defaults to the first available method
 */
const firstAllowedMethod = computed(() => {
  let serviceOrPackageService = state.service;

  if (state.service?.service) {
    serviceOrPackageService = state.service.service;
  }

  if (serviceOrPackageService?.booking_options?.allow_standard && isServiceMethodAvailable.value.in_person) {
    return 'in_person';
  }
  if (serviceOrPackageService?.booking_options?.allow_standard && isServiceMethodAvailable.value.video) {
    return 'video';
  }
  if (serviceOrPackageService?.booking_options?.allow_standard && isServiceMethodAvailable.value.phone) {
    return 'phone';
  }

  return null;
})

// todo: check if this is refreshing correctly
const isServiceMethodAvailable = computed(() => {
  let serviceOrPackageService = state.service;

  if (state.service?.service) {
    serviceOrPackageService = state.service.service;
  }

  return {
    in_person: serviceOrPackageService.availability?.some(item => item.in_person == 1),
    video: serviceOrPackageService.availability?.some(item => item.video == 1),
    phone: serviceOrPackageService.availability?.some(item => item.phone == 1),
  }
});

const handleTimeNavigation = async (direction) => {
  const currentDate = state.booking.date;
  if (!currentDate) return;

  // Calculate the next/previous date based on direction
  const newDate = direction === 'next'
      ? moment(currentDate).add(1, 'day').toDate()
      : moment(currentDate).subtract(1, 'day').toDate();

  // Update the date and load new times
  state.booking.date = newDate;
  await loadAvailableTimes();
};

// Utility function
const loadAvailabilityForDate = async (date: moment.Moment) => {
  let serviceId = state.service?.service?.id || state.service?.id;

  if (!serviceId || !state.booking.method) {
    snackbar.errorSnackbar("Please select a service and method");
    return;
  }

  try {
    const formattedDate = date.format('DD/MM/YY');
    const response = await servicesService.getServiceAvailability(serviceId, {
      date: formattedDate,
      method: state.booking.method
    });

    state.availableDays = response.data;
    return response;
  } catch (err) {
    console.error('Availability load error:', err);
    snackbar.errorSnackbar("Failed to load availability");
  }
};

// Event handlers
const handleDateSelect = async (newDate) => {
  await loadAvailabilityForDate(moment(newDate));
};

const handleMonthChange = async (newMonth) => {
  // Keep the same year when changing months
  const currentYear = state.pickerState.currentYear;
  state.pickerState.currentMonth = newMonth;

  const selectedDate = moment()
      .year(currentYear)
      .month(newMonth)
      .startOf('month');

  await loadAvailabilityForDate(selectedDate);

  // Reset selections
  state.booking.date = null;
  state.booking.time = null;
  state.availableTimes = [];
};

const handleMethodChange = async (newValue) => {
  state.booking.method = newValue.method;

  if (state.booking.date) {
    await loadAvailableTimes();
  }
};

const handleYearChange = async (newYear) => {
  // Only called when explicitly changing year from year selector
  state.pickerState.currentYear = newYear;

  const selectedDate = moment()
      .year(newYear)
      .month(state.pickerState.currentMonth)
      .startOf('month');

  await loadAvailabilityForDate(selectedDate);

  // Reset selections
  state.booking.date = null;
  state.booking.time = null;
  state.availableTimes = [];
};

onMounted(() => {
  loadService();
});
</script>

<style lang="scss" scoped>
.date-picker-container {
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  margin-bottom: 30px;
}

:deep(.add-new-button-container) {
  left: 0;
  right: 0;
  margin: auto;
  padding: 28px 23px;
}

.border-radius {
  border-radius: 10px;
}
</style>