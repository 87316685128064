<template>
  <v-card class="font-size-12-400 mx-5 my-3 card-container">

    <div class="d-flex justify-space-between px-5 pt-4">
      <div class="font-size-12-400">
        {{ getConsultationDateAndDuration.date }}
      </div>
      <div class="font-size-12-400 d-flex align-center ga-1">
        {{ getConsultationDateAndDuration.duration }}
        <div class="d-flex align-center ga-1">
          <span v-if="getMethodIcon" class="secondary-button-color">|</span>
          <Icon v-if="getMethodIcon" :icon="getMethodIcon"/>
        </div>
      </div>
    </div>

    <v-sheet :class="{'card-container-sheet': getAvatar, 'card-container-sheet-unconfirmed': !getAvatar}" class="pa-4">
      <v-sheet v-if="getAvatar" class="avatar-container">
        <div v-if="getAvatar.image" :style="{ backgroundImage: `url(${getAvatar.image})` }"
             class="avatar-container__image"></div>
        <div v-else class="avatar-container__initials primary-background-button-color">{{ getAvatar.initials }}</div>
        <v-overlay
            activator="parent"
            location-strategy="connected"
            scroll-strategy="close"
        >
          <v-card class="pa-2">
            {{ getAvatar.name }}
          </v-card>
        </v-overlay>
      </v-sheet>

      <div class="consultation-description-container">
        <p class="font-size-14-600 ">
          {{ consultation?.clinic_service?.name ?? '-' }}
        </p>
        <p class="subtitle pb-1">
          ({{ getPackagesOrServices }})
        </p>
        <p class="font-size-12-600 d-flex align-center ga-1">
          {{ consultation?.consultation?.order?.total_display ?? '-' }}
          <span
              :class="{'font-success': getPaymentStatus === 'Paid','font-danger': getPaymentStatus === 'Not paid', 'font-warning': getPaymentStatus === 'Part paid'}"
              class="font-size-10-400"
          >
            ({{ getPaymentStatus }})
          </span>
        </p>
        <p class="font-size-10-400 font-grey-dark ">
          Order ID:
          <span class="font-size-10-600 primary-button-color text-underline">
            #{{
              consultation?.consultation.order_id ?? ''
            }}
          </span>
        </p>
      </div>

      <div v-if="tab !== 'cancelled'">
        <v-btn
            v-if="tab === 'upcoming' && getMethodIcon === 'video-icon' && consultation?.assignee"
            class="b-round-20 outline-btn primary-button mb-2"
            elevation="0"
            height="32px"
            variant="outlined"
            x-small
            @click="onJoin"
        >
          <span class="mr-1">Join</span>
          <Icon icon="video-icon-lg"></Icon>
        </v-btn>
        <v-btn
            v-if="tab === 'upcoming' && !consultation?.event"
            class="b-round-20 outline-btn primary-button mb-2"
            elevation="0"
            height="32px"
            variant="outlined"
            width="82px"
            x-small
            @click="book"
        >
          Book
        </v-btn>
        <v-btn
            class="b-round-20 outline-btn white-btn"
            elevation="0"
            height="32px"
            variant="outlined"
            width="82px"
            x-small
            @click="router.push({ name: 'order-view', params: { id: consultation?.consultation.order_id } })"
        >
          View
        </v-btn>
      </div>
    </v-sheet>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, defineProps} from "vue";
import {useRouter} from "vue-router";
import moment from 'moment';
import Icon from "@/components/Icons/Icon.vue";

const router = useRouter();

const props = defineProps({
  consultation: {
    type: Object,
  },
  tab: {
    type: String
  },
});

const getAvatar = computed(() => {
  if (props.consultation.assignee_id && props.consultation.assignee) {
    const firstName = props.consultation.assignee.first_name ?? '';
    const lastName = props.consultation.assignee.last_name ?? '';

    if (props.consultation.assignee.image && props.consultation.assignee.image.file) {
      return {
        image: props.consultation.assignee.image.file.file_path ?? '',
        initials: null,
        name: `${firstName} ${lastName}`
      };
    }

    if (firstName || lastName) {
      return {
        image: null,
        initials: (firstName[0] + (lastName[0] || '')).toUpperCase(),
        name: `${firstName} ${lastName}`
      };
    }
  }
  return null;
});

const getConsultationDateAndDuration = computed(() => {
  if (props.consultation?.event !== null) {
    return {
      duration: props.consultation?.event.duration ?? '',
      date: moment(props.consultation?.event.start_date).format("DD MMMM, YYYY, h:mm a") ?? '',
    }
  }

  //unconfirmed or canncelled
  const dateString = props.consultation?.consultation?.status == 'cancelled' ? 'Cancelled' : 'Unconfirmed';

  return {
    duration: '-',
    date: dateString,
  }
})

const getMethodIcon = computed(() => {
  let icon;

  switch (props.consultation?.method) {
    case 'In Person':
      icon = 'location-icon'
      break
    case 'Phone Call':
      icon = 'phone-icon'
      break
    case 'Video':
      icon = 'video-icon'
      break
    default:
      icon = null
  }

  return icon
})

const getPackagesOrServices = computed(() => {
  return props.consultation?.consultation?.package_id ? props.consultation?.consultation?.package?.name : 'Service'
})

const getPaymentStatus = computed(() => {
  switch (props.consultation?.consultation?.order?.payment_status) {
    case 'paid':
      return 'Paid'
    case 'partial':
      return 'Part paid'
    default:
      return 'Not paid'
  }
})

const onJoin = () => {
  if (!props.consultation.link) return null;

  window.location.href = props.consultation.link;
}

const book = () => {
  if (props.consultation.clinic_package_id) {
    let clinicServiceId = props.consultation.consultation.package.services.filter((item) => {
      return props.consultation.clinic_service_id === item.clinic_service_id;
    })

    router.push({
      name: 'book-service', params: {id: props.consultation.clinic_service?.id}, query: {
        packageServiceId: clinicServiceId[0].id,
        orderId: props.consultation.consultation.order_id,
        consultationId: props.consultation.consultation_id
      }
    })
  } else {
    router.push({name: 'book-service', params: {id: props.consultation?.clinic_service_id}})
  }
}
</script>

<style lang="scss" scoped>
.card-container {
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 15px 0px #0000000D;
  border: 1px solid #F0F0F0;

  .card-container-sheet {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    border-radius: 30px;
    gap: 8px;
  }

  .card-container-sheet-unconfirmed {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    border-radius: 30px;
    gap: 8px;
  }

  .avatar-container {
    border-radius: 100%;
    width: 50px;
    height: 50px;

    &__image {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 14px;
      border-radius: 50%;
    }
  }

  .consultation-description-container {
    .subtitle {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
    }
  }

  .text-underline {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}
</style>