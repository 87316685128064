<template>
  <v-sheet class="position-relative px-2">
    <v-sheet>
      <div class="d-flex align-center w-100 ga-4 mb-3">
        <div class="w-100">
          <v-label class="font-size-12-600 font-black mb-0">First Name</v-label>

          <v-text-field
              v-model="state.user.first_name"
              density="compact"
              disabled
              elevation="0"
              hide-details
              placeholder="First Name"
              variant="solo"
              width="100%"
          />
        </div>
        <div class="w-100">
          <v-label class="font-size-12-600 font-black mb-0">Last Name</v-label>

          <v-text-field
              v-model="state.user.last_name"
              density="compact"
              disabled
              elevation="0"
              hide-details
              placeholder="Last Name"
              variant="solo"
              width="100%"
          />
        </div>
      </div>
      <div class="d-flex align-center w-100 ga-4">
        <div class="w-100">
          <v-label class="font-size-12-600 font-black mb-0">Email</v-label>

          <v-text-field
              v-model="state.user.email"
              density="compact"
              disabled
              elevation="0"
              hide-details
              placeholder="Email"
              variant="solo"
              width="100%"
          />
        </div>
        <div class="w-100">
          <v-label class="font-size-12-600 font-black mb-0">Phone Number</v-label>

          <v-text-field
              v-model="state.user.phone"
              density="compact"
              disabled
              elevation="0"
              hide-details
              placeholder="Phone Number"
              variant="solo"
              width="100%"
          />
        </div>
      </div>
    </v-sheet>

    <section class="pt-3">
      <ButtonFixed
          :disabled="!isBookingReady || creatingBooking"
          custom-class="text-white primary-button confirm-button"
          text="Complete Booking"
          width="100%"
          @click="createBooking"
      />
    </section>
  </v-sheet>
</template>

<script lang="ts" setup>
import {defineProps, ref, computed, reactive} from 'vue'
import moment from "moment/moment";
import {consultationService} from "@/api/services/consultation";
import {useRoute, useRouter} from "vue-router";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";

const router = useRouter();
const route = useRoute();

const props = defineProps({
  state: Object,
  userStore: Object,
  packageServiceId: Number,
})

const state = reactive({
  user: props.userStore.user,
})

const creatingBooking = ref(false);

const isBookingReady = computed(() => {
  if (props.state.booking.method && props.state.booking.date && props.state.booking.time && props.state.booking.practitioner && props.state.booking.termsAgreed) {
    return true;
  }
  return false;
});

const createBooking = () => {
  if (creatingBooking.value) {
    return;
  }

  creatingBooking.value = true;

  const date = props.state.booking.date;
  const time = props.state.booking.time;

  const formattedDate = moment(date).format('DD/MM/YY');

  const datetimeString = `${formattedDate} ${time}`;

  const timezone = props.userStore.user.clinic.timezone || 'Europe/London';

  // Parse the combined date and time string in the user's local timezone
  const localDatetime = moment.tz(datetimeString, 'DD/MM/YY HH:mm', timezone);

  // Convert the local datetime to UTC
  const utcDatetime = localDatetime.utc();

  // Format the datetime to 'YYYY-MM-DD HH:mm:ss' format in UTC
  const formattedDatetime = utcDatetime.format('YYYY-MM-DD HH:mm:ss');

  let serviceOrPackageIds = {
    service_id: props.state.service.id,
  };

  if (props.state.service.clinic_service_id) {
    serviceOrPackageIds = {
      service_id: props.state.service.clinic_service_id,
      package_id: props.state.service.clinic_package_id,
      package_service_id: +props.packageServiceId,
    };
  }

  consultationService.create({
    ...serviceOrPackageIds,
    practitioner_id: props.state.booking.practitioner.id,
    method: props.state.booking.method,
    start_date: formattedDatetime,
    order_id: route.query.orderId,
    consultation_id: route.query.consultationId,
  }).then((response) => {
    router.push({name: 'order-view', params: {id: response.order.id}})
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    creatingBooking.value = false;
  });
}
</script>

<style lang="scss" scoped>
:deep(.v-field--variant-solo, .v-field--variant-solo-filled) {
  box-shadow: none;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
</style>