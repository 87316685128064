<template>
  <section v-if="!isShipping" class="order-card-item-details">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else :class="{'secondary-background-button-color': !item?.supplier }" class="icon-container">
      <Icon :icon="getIcon"/>
    </v-sheet>

    <v-card-text class="card-description pa-0 ">
      <section class="d-flex justify-space-between">
        <div>
          <p class="font-size-12-600">{{ getTitle }}</p>
          <p v-if="getBrandName" class="font-size-10-400 mt-2">{{ getBrandName }}</p>
          <p class="font-size-10-400 mt-2 font-grey-dark">{{ getSku }}</p>
          <p v-if="getSupplierName" class="font-size-10-400 mt-2">Supplier</p>
          <p v-if="getSupplierName" :class="{'secondary-background-button-color': !item?.supplier }"
             class="font-size-10-600 mt-2 supplier font-grey-dark"
          >
            {{ getSupplierName }}
          </p>
        </div>
        <div :class="{'disabled': disabled}" class="text-right font-size-16-600 pt-0" @click="emit('delete')">
          <p>
            <Icon icon="trash-icon"/>
          </p>
        </div>
      </section>

      <section class="d-flex align-center justify-space-between mt-3">
        <slot></slot>
        <p class="font-size-16-600">{{ getPrice }}</p>
      </section>

      <section v-if="getAttributesForClinicOrSupplier?.length > 0">
        <div class="text-right pa-0">
          <v-btn
              :ripple="false"
              class="primary-underline-button text-capitalize font-size-12-600 pa-0"
              variant="plain"
              @click="toggleText"
          >
            Show More
          </v-btn>
        </div>

        <OrderDetailsDialog
            v-model="showMore"
            :attributes="getAttributesForClinicOrSupplier"
            :is-supplier="getSupplierName === 'Natural Dispensary'"
        />
      </section>
    </v-card-text>
  </section>
</template>

<script lang="ts" setup>
import {defineProps, computed, ref} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import {useUserStore} from "@/api/store/user";
import {defineEmits} from 'vue';
import OrderDetailsDialog from "@/views/OrdersComponents/OrderDetails/ProductDetails/OrderDetailsDialog.vue";
import {loadPanelData} from "@/views/ShopComponents/ItemComponents/PanelDataForSupplier";

const emit = defineEmits(['delete']);

const userStore = useUserStore();

const props = defineProps({
  item: {
    type: Object,
  },

  disabled: {
    type: Boolean,
    default: false
  }
});

const showMore = ref(false);

const toggleText = () => {
  showMore.value = !showMore.value;
};

const getTitle = computed(() => {
  if (props.item?.product) {
    return props.item.product.name;
  }

  return props.item?.name;
})

const getImage = computed(() => {
  if (props.item?.product && props.item?.product?.images !== undefined) {
    return props.item?.product?.images[0]?.full ?? null;
  }

  return null;
})

const getIcon = computed(() => {
  const type = props.item.product?.type ?? null;

  if (type === 'clinic_product' || type === 'supplement' || type === 'product') {
    return 'product-icon';
  }

  return null;
})

const getPrice = computed(() => {
  return `${props.item.currency}${(props.item.product?.price_retail * props.item.quantity).toFixed(2)}` ?? '';
})

const getBrandName = computed(() => {
  if (props.item?.product && props.item?.product?.brand) {
    return `(${props.item?.product?.brand?.name})`;
  }

  return '';
})

const getSku = computed(() => {
  if (props.item?.product) {
    return props.item.product.sku;
  }

  return '';
})

const isShipping = computed(() => {
  return props.item.product && props.item.product.type === 'shipping_method';
})

const getSupplierName = computed(() => {
  if (props.item.supplier) {
    return props.item?.supplier?.name ?? null;
  }

  return userStore.user.clinic.name ?? null;
})


const getAttributesForClinicOrSupplier = computed(() => {
  if (getSupplierName.value === 'Natural Dispensary') {
    return loadPanelData(props.item?.product)
  }

  return props.item?.product?.attributes;
})
</script>

<style lang="scss" scoped>
.order-card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .logo-container {
    width: 126.61px;
    height: 122.57px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.icon-container {
  border-radius: 5px;
  width: 126.61px;
  height: 122.57px;
  background: #3A5485;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supplier {
  background: #3A5485;
  color: white;
  padding: 2px 6px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.disabled {
  pointer-events: none;
}
</style>