<template>
    <div class="w-100 py-2 px-10 mt-4 mb-2 mx-0" style="height:100px; display: flex; justify-content: center;">
        <v-img :src="image ?? settingsStore.settings.brandingImg" class="mx-auto" style="min-height: 80px" />
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useSettingsStore } from '@/api/store/settings';
const settingsStore = useSettingsStore();

defineProps({
    image: {
        type: String as () => string | null,
        default: null
    }
})
</script>